import React, { useState } from 'react';
import AWS from 'aws-sdk';
import {API} from 'aws-amplify';
import swal from 'sweetalert';

AWS.config.update({region:'ap-south-1'});

const initialValues = {
    "rnName":"",
    "rnEmail":"",
    "rnSubject":"",
    "rnMessage":""
}

const ContactForm = (Props) => {

    
    const [emailForm, setEmailForm] = useState(initialValues);

    const onChangeHandler = (e) => {
        var tempValues = {
            ...emailForm
        }
        tempValues[e.target.name] = e.target.value;
        setEmailForm(tempValues);
    }

    const sendEmail = async (e) => {

        e.preventDefault();

        const myInit = { 
            body: {...emailForm}, 
        };

        API.post("TTContactForm","/contactform", myInit).then((test)=>{
            if(test.status && test.status==="success"){
                setEmailForm(initialValues);
                swal("Thank you!", "Your message has been successfully sent. We will contact you very soon!", "success");
            }
        })
   
    }

    return(
        <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Contact Us</h2>

                            </div>
                            <div className="form-wrapper">
                                <form onSubmit={sendEmail}>
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="rnName"
                                            id="rnName"
                                            value={emailForm.rnName}
                                            onChange={onChangeHandler}
                                            placeholder="Your Name *"
                                            required
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="rnEmail"
                                            id="rnEmail"
                                            value={emailForm.rnEmail}
                                            onChange={onChangeHandler}
                                            placeholder="Your email *"
                                            required
                                        />
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="rnSubject"
                                            id="rnSubject"
                                            value={emailForm.rnSubject}
                                            onChange={onChangeHandler}
                                            placeholder="Write a Subject"
                                        />
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="rnMessage"
                                            name="rnMessage"
                                            value={emailForm.rnMessage}
                                            onChange={onChangeHandler}
                                            placeholder="Your Message"
                                        />
                                    </label>
                                    <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 email ">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                            <div className="rn-address">
                                <div className="inner">
                                    <h4 className="title">Email Address</h4>
                                    <p><a href="mailto:admin@gmail.com">contact@tekkihub.com</a></p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default ContactForm;