import React, { Fragment } from 'react';
import { FaMobile, FaDesktop, FaCogs } from "react-icons/fa";

const ServiceList = (props) => {
    const ServiceList = [
        {
            icon: <FaDesktop />,
            title: 'Website Development',
            description: 'There are three responses to a piece of design, \nYes, \nno \nWow!\n\n WOW!!! is the one we aim for.'
        },
        {
            icon: <FaMobile />,
            title: 'Mobile App Development',
            description: 'Mobile application is the modern way of reaching customers personally. We make it simple for you '
        },
        {
            icon: <FaCogs />,
            title: 'Software Service',
            description: 'Software is the magic thing whose importance only goes up over time'
        }
    ]
    return(
        <Fragment>
            <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className={`${props.column}`} key={i}>

                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{
                                          val.description.split("\n").map(function(item,idx){
                                            return(<span key={idx}>
                                                {item}<br/>
                                              </span>)
                                          })}</p>
                                    </div>
                                </div>

                        </div>
                    ))}
                </div>
        </Fragment>
    )
}

export default ServiceList;