import React from 'react';
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";
import logoUrlSrc from '../../assets/images/logo/logo.png'
import logoUrlSymbolLight from '../../assets/images/logo/logo-symbol-light.png';
import logoUrlSymbolDark from '../../assets/images/logo/logo-symbol-dark.png';
import logoUrlLight from '../../assets/images/logo/logo-light.png';
import logoUrlDark from '../../assets/images/logo/logo-dark.png';
import config from '../Config/config.json';


const Header = (props) => {

    const menuTrigger = () => {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    const CLoseMenuTrigger = () => {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }

    let logoUrl;
        if(props.logo === 'light'){
            logoUrl = <img src={logoUrlLight} alt={config.companyName} style="max-width: 12em;"/>;
        }else if(props.logo === 'dark'){
            logoUrl = <img src={logoUrlDark} alt={config.companyName} style="max-width: 12em;"/>;
        }else if(props.logo === 'symbol-dark'){
            logoUrl = <img src={logoUrlSymbolDark} alt={config.companyName} style="max-width: 12em;"/>;
        }else if(props.logo === 'symbol-light'){
            logoUrl = <img src={logoUrlSymbolLight} alt={config.companyName} style="max-width: 12em;"/>;
        }else{
            logoUrl = <img src={logoUrlSrc} alt={config.companyName} />;
        }

    return (
        <header className={`header-area formobile-menu header--transparent default-color`}>
            <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/">
                                {logoUrl}
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about" >About</Link></li>
                                <li><Link to="/portfolio">Portfolio</Link></li>
                                <li><Link to="/contact" >Contact</Link></li>
                            </ul>
                        </nav>
                       
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
        </header>
    )
}

export default Header;
