import React, { Fragment } from 'react';
import Breadcrumb from './Components/Breadcrumb';
import Header from './Components/Header';
import PageHelmet from './Components/Helmet';
import AboutImgSrc from '../assets/images/about/about-3-tt.jpg';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Footer from './Components/Footer';

const About = (props) => {
    const title = 'About',
        description = 'Tekkihub Technologies, Yes. We are startup. We focus on understanding the real-time problem of our customer and giving them solution through technology.';
    return (
        <Fragment>
            <PageHelmet pageTitle='About' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'About'}   />
            {/* End Breadcrump Area */}
            {/* Start About Area  */}
            <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src={AboutImgSrc} alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who we are</h3>
                                                    <p>We are group of people having good experience in technologies.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who we are</h3>
                                                    <p>We are able to understand your requirement sharply and able to give solution.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}
                 {/* Start Back To Top */}
                 <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
        </Fragment>
    )
}

export default About;