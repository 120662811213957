import React, { Fragment } from 'react';
import { Link } from "react-router-dom";

const Breadcrumb = (props) => {
    return(
        <Fragment>
            <div className="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--1" data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner pt--100">
                                    <h2 className="title">{props.title}</h2>
                                    <ul className="page-list">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        {props.parent? <li className="breadcrumb-item">{props.parent}</li>:''}
                                        <li className="breadcrumb-item active">{props.title}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </Fragment>
    )
}

export default Breadcrumb;