import React, { Fragment } from 'react';
import { FiChevronUp } from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';
import Breadcrumb from './Components/Breadcrumb';
import Footer from './Components/Footer';
import Header from './Components/Header';
import PageHelmet from './Components/Helmet';
import PortfolioList from './Components/PortfolioList';

const Portfolio = (props) =>{
    return (
        <Fragment>
            <PageHelmet pageTitle='Portfolio' />
            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Portfolio'}   />
            {/* End Breadcrump Area */}
            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--5">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30">
                                        <h2 className="title">All Works</h2>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                            </div>
                            
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                {/* Start Footer Area  */}
                <Footer />
                {/* End Footer Area  */}


            </main>
            {/* End Page Wrapper  */}
        </Fragment>
    )
}

export default Portfolio;