import React, {Fragment} from 'react';
import Header from './Components/Header';
import Helmet from './Components/Helmet';
import Slider from 'react-slick';
import ServiceList from './Components/ServiceList';
import PortfolioList from './Components/PortfolioList';
import CallAction from './Components/CallAction';
import Footer from './Components/Footer';
import { slideSlick } from "../Tekkihub/Config/slicker-config";
import { FiCheck, FiChevronUp } from "react-icons/fi";
import AboutImgSrc from '../assets/images/about/about-3-tt.jpg';
import ScrollToTop from 'react-scroll-up';

const Home = (props) => {
    const SlideList = [
        {
            textPosition: 'text-left',
            bgImage: 'bg_image--17',
            category: '',
            title: 'We are here',
            description: 'The market is moving towards technologies. While you focus on business, let us help you to compete in business through technology',
            buttonText: 'Contact Us',
            buttonLink: '/contact'
        }/*,
        {
            textPosition: 'text-left',
            bgImage: 'bg_image--18',
            category: '',
            title: 'Develop Business?',
            description: 'Again, be a problem solver',
            buttonText: 'Contact Us',
            buttonLink: '/contact'
        }*/
    ]

    const namesItemOne = [
        'Our passion on Technology',
        'We do it for passion not a business',
        'Dirty hands with perfect solution in Technology',
    ];
    
    return(
        <Fragment>
            <Helmet pageTitle="Home" />
            <Header />
            {/* Start Slider Area   */}
            <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="8">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}
                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                                    <h2 className="title">Our Service</h2>
                                    <p>While you focus on your business, we are here to support you from technology front</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="3" column="col-lg-4 col-md-6 col-sm-6 col-12 text-center" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}
                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--3">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">About</h2>
                                        <p className="description">Tekkihub Technologies,<br></br> Startup? Yes, we are. We focus on understanding the real-time problem of our customer and giving them perfect solution through technology. </p>
                                    </div>
                                    <div className="mt--30">
                                        <h4>Why Us?</h4>
                                        <ul className="list-style--1">
                                            {namesItemOne.map((name, index) => {
                                                return <li key={ index }><FiCheck /> {name}</li>;
                                            })}
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail position-relative">
                                    <img className="w-100" src={AboutImgSrc} alt="About Images"/>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}
                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--5">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">Our Project</h2>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                            </div>

                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}
                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}
                {/* Start Footer Style  */}
                <Footer />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
        </Fragment>
    )
}

export default Home;