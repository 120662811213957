import React from 'react';
import {Helmet} from 'react-helmet';

const PageHelmet = (props) => {
    return ( <React.Fragment>
        <Helmet>
            <title>{props.pageTitle} || Tekkihub Technologies</title>
            <meta name="description" content="Tekkihub Technologies" />
        </Helmet>
    </React.Fragment>)
}

export default PageHelmet;