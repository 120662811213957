import React, {Fragment} from 'react'


const PortfolioList = (props) => {

    const PortfolioListContent = [
        {
            image: 'image-4',
            category: 'Agents\' Diary',
            title: 'Insurance Agent\'s Management Portal with policy expiration alerts',
            url: '/agents-diary'
        },
        {
            image: 'image-4',
            category: 'Kamala Venkatraman Trust',
            title: 'Portfolio of chennai based trust',
            url: '/kvtrust'
        },
        {
            image: 'image-4',
            category: 'Superior Solutions',
            title: 'Portfolio of HVAC based company',
            url: '/superior-solutions'
        },
        {
            image: 'image-4',
            category: 'SpotlessPixels',
            title: 'Studio Album Designs and Template Gallery',
            url: '/spotlesspixels'
        },
        {
            image: 'image-4',
            category: 'Classy',
            title: 'User friendly assignment portal for students, teachers',
            url: '/classy'
        },
        {
            image: 'image-4',
            category: 'Finance Manager',
            title: 'Simple web application for financiers',
            url: '/finance-manager'
        }
    
    
    ]
    return (
        <Fragment>
{PortfolioListContent.map((value , index) => (
                    <div className={`${props.column}`} key={index}>
                        <div className={`portfolio ${props.styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <h4><t><a href={value.url}>{value.category}</a></t></h4>
                                    <h4><d><a href={value.url}>{value.title}</a></d></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={value.url}>View Details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
        </Fragment>
    )
}

export default PortfolioList;