import HomeComponent from './Tekkihub/Home';
import About from './Tekkihub/About';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Portfolio from './Tekkihub/Portfolio';
import Contact from './Tekkihub/Contact';

//Portfolio details
import AgentsDiary from './Tekkihub/Elements/AgentsDiary';
import Classy from './Tekkihub/Elements/Classy';
import KVTrust from './Tekkihub/Elements/KVTrust';
import SuperiorSolutions from './Tekkihub/Elements/SuperiorSolutions';
import SpotlessPixels from './Tekkihub/Elements/SpotlessPixels';
import FinanceManager from './Tekkihub/Elements/FinanceManager';

function App() {
  return (
    <BrowserRouter basename={'/'}>
      <Switch>
        <Route exact path="/" component={HomeComponent}/>
        <Route exact path="/about" component={About}/>
        <Route exact path="/portfolio" component={Portfolio}/>
        <Route exact path="/contact" component={Contact}/>
        <Route exact path={`${process.env.PUBLIC_URL}/agents-diary`} component={AgentsDiary} />
        <Route exact path={`${process.env.PUBLIC_URL}/classy`} component={Classy} />
        <Route exact path={`${process.env.PUBLIC_URL}/kvtrust`} component={KVTrust} />
        <Route exact path={`${process.env.PUBLIC_URL}/superior-solutions`} component={SuperiorSolutions} />
        <Route exact path={`${process.env.PUBLIC_URL}/spotlesspixels`} component={SpotlessPixels} />
        <Route exact path={`${process.env.PUBLIC_URL}/finance-manager`} component={FinanceManager} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
