import React, { Fragment } from 'react';
import { FiChevronUp } from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';
import Breadcrumb from './Components/Breadcrumb';
import Footer from './Components/Footer';
import Header from './Components/Header';
import PageHelmet from './Components/Helmet';
import ContactForm from './Utils/ContactForm';

const Contact = (props) => {
    return(
        <Fragment>
            <PageHelmet pageTitle='Contact' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Contact'}   />
            {/* End Breadcrump Area */}
            {/* Start Contact Page Area  */}
            <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactForm />
                </div>
                {/* End Contact Page Area  */}
         
                 {/* Start Back To Top */}
                 <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />
        </Fragment>
    )
}

export default Contact;