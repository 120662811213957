import React, { Component } from "react";
import PageHelmet from "../Components/Helmet";
import ModalVideo from 'react-modal-video';
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]

class AgentsDiary extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Portfolio Details' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--4"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Getting tickets to the big show</h2>
                                    <p>Store your customer's policy. We will remind you</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>Agents' Diary</h2>
                                        <p className="subtitle">Agents’ diary sends SMS automatically to your policyholders whose policy expires in 15 days about the due date and vehicle registration number. SMS also comprises the agent’s name along with his mobile number and it facilitates to call you to renew the policy.</p>
                                        <p>We introduce this App to remind the agents like you to find out the policyholders whose policy expires in the next 15 days from your plenty of clients. This will remind you to renew the policy of your valuable client IN TIME. It is always better to renew the policy BEFORE DUE DATE to avoid risk.</p><p>

Many of the policyholders are not always aware about the expiry date of their policy in their busy schedule. Its well, that if they are reminded by the Insurance companies or by agents. But they are not responsible to the policyholders to remind the expiry of the policy. So, if there is any risk, the policyholder cannot sue the Insurance company or agent if they fail to send reminder notice.</p><p>

Agents are in need to remind the policyholders to renew the policy as to retain their business. It is not an easy job for an agent to find out all policy holders’ due date in time. In this situation, the Agents’ diary App supports you a full-fledged solution at a minimum cost.</p>

                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Branch</span>
                                                <h4>Insurance</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Types</span>
                                                <h4>Website, Mobile App</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Web Link</span>
                                                <h4><a href="https://cloud.tekkihub.com">View Project</a></h4>
                                            </div>
                                        </div>

                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default AgentsDiary;
